.userIcon {
  border-radius: 100%;
  border: solid;
  border-width: 2px;
  border-color: white;
  filter: drop-shadow(0px 0px 4px black)
}

p {
  margin: 0;
  padding: 0;
}

h1 {
  margin: 0;
  padding: 0;
}

.buttons {
  position: relative;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: .5rem;
}

.buttons button {
  margin-bottom: 10px;
}

.deleteAccount {
  background-color: rgba(0, 0, 0, 0.98);
  width: 70%;
  height: 400px;
  left: 15%;
  top: 100px;
  position: fixed;
  box-shadow: 0px 0px 10px rgb(73, 32, 32);
  display: none;
  border-radius: 15px;
  text-align: center;
  justify-content: center;
  color: white;
}

@media only screen and (max-width: 1300px) {}