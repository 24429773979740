.mainContainer {
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  background: linear-gradient(to bottom, #ffffffcf, rgb(137, 137, 137));
  backdrop-filter: blur(10px);
}

.container {
  padding-top: 110px;
  width: 100vw;
  left: 0px;
  margin-top: 0px;
  margin-bottom: 40px;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  height: 100px;
  align-items: center;
  justify-content: space-evenly;
}

.contTitleArtistDesc {
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: .3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-color: #171717;
  border-width: 1px;
  border-radius: 15px;
  gap: .5rem;
  position: relative;
}

.contTitleArtistDesc span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.contTitleArtistDesc span label {
  position: absolute;
  top: 0;
  font-size: 12px;
  margin-top: -3px;
  margin-left: -.25rem;
  background-color: #171717;
  border-radius: 3px;
  color: white;
  padding-left: .5rem;
  padding-right: .5rem;
}

.contTitleArtistDesc span input {
  padding-top: 11px;
  width: 100%;
}

.contTitleArtistDesc span textarea {
  padding-top: 11px;
  width: 100%;
  min-height: 46px;
  max-height: 300px;
  resize: vertical;
  form-sizing: content;
  font-family: Poppins;
}

.publishContainer {
  display: flex;
  gap: .5rem;
  align-items: center;
  justify-content: center;
}

.imgSlrVsr {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: center;
  border-color: #171717;
  border-width: 1px;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: 1rem;
}

.imgSlrVsr span {
  display: flex;
  flex-direction: column;
  width: 180px;
}

.imgSlrVsr label {
  background-color: #171717;
  color: #fff;
  border-radius: 5px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: -.6rem;
  z-index: 1;
}

.inputBtn:hover {
  cursor: pointer;
}

textarea {
  resize: none;
}

.btnVolver {
  position: fixed;
  top: 3rem;
  left: 50px;
  width: 180px;
  height: 40px;
  font-family: Bebas Neue;
  font-size: 17px;
  background: linear-gradient(to bottom, #ffffff, rgba(0, 0, 0, 0.679));
  border: none;
  box-shadow: 0px 0px 20px black;
}

.btnContinuar {
  position: fixed;
  bottom: 150px;
  right: 50px;
  width: 180px;
  height: 40px;
  font-family: Bebas Neue;
  font-size: 20px;
  background: linear-gradient(to bottom, #ffffff, rgba(0, 0, 0, 0.679));
  border: none;
  box-shadow: 0px 0px 20px black;
}

.btnContinuar:hover,
.btnVolver:hover {
  background: linear-gradient(to bottom, #171717, rgba(0, 0, 0, 0.679));
  background-color: #171717;
  color: #ffffff;
  transition-duration: 0.2s;
}

.divisor {
  width: 50vw;
  margin-left: 25vw;
  height: 10px;
  background-color: #171717;
  margin-bottom: 20px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  filter: drop-shadow(0px 0px 10px black)
}

.checkboxes {
  padding: 1rem;
  border: dashed;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  background: rgb(187, 187, 187);
}

.checkboxes button {
  background-color: #171717;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding-left: .7rem;
  padding-right: .7rem;
}

.checkboxTitle {
  display: flex;
  width: 180px;
  justify-content: space-between;
}

.actionsContainer {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  gap: 1rem;
}

.actionPrimary {
  padding: 0.6em 0.8em;
  width: 180px;
  font-family: Bebas Neue;
  font-size: 20px;
  color: white;
  background-color: rgb(32, 32, 32);
  border: none;
  border-radius: 40px;
}

.actionPrimary:hover {
  background-color: rgb(78, 78, 78);
  box-shadow: 0px 0px 15px white;
  cursor: pointer;
}

.actionSecondary {
  padding: 0.6em 0.8em;
  width: 180px;
  font-family: Bebas Neue;
  font-size: 20px;
  color: white;
  background-color: rgb(88, 88, 88);
  border: none;
  border-radius: 40px;
}

.actionSecondary:hover {
  box-shadow: 0px 0px 15px white;
  background-color: rgb(32, 32, 32);
}
