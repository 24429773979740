.donateFormat {
  margin-top: 100px;
  text-align: center;
  font-size: 25px;
}

.ulContBtn {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: .5rem;
}

.btnSubmit {
  background: transparent;
  height: 40px;
  width: 200px;
  bottom: 30px;
  border: none;
  border-radius: 70px;
  background-color: #171717;
  filter: drop-shadow(0px 0px 4px black);
  color: aliceblue;
  font-family: Bebas Neue;
  font-size: 20px;
  transition-duration: .3s;
  border: none;
}

.backSubmit {
  background: transparent;
  margin: 20px;
  margin-left: 20px;
  height: 40px;
  width: 200px;
  bottom: 30px;
  border: none;
  border-radius: 70px;
  background-color: #171717;
  filter: drop-shadow(0px 0px 4px black);
  font-family: Bebas Neue;
  font-size: 20px;
  transition-duration: .3s;
  border: none;
  color: aliceblue;
}

.backSubmit:hover {
  background-color: #292929;
}

.btnSubmit:hover {
  background-color: rgb(231, 231, 231);
  color: #171717;
}