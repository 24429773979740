.auth {
  padding: 0;
  margin: 0;
  border-radius: 5px;
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  display: flex;
  align-items: center;
  text-align: center;
}

.button {
  padding: 0;
  border: none;
  border-radius: 5px;
  background: linear-gradient(172deg, #171717, rgb(78, 56, 111));
  width: 260px;
  height: 40px;
  font-size: 13px;
  color: white;
  text-shadow: 0px 0px 1px rgba(50, 50, 50, 0.752);
  font-weight: bolder;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition-duration: .4s;
}

.button:hover {
  background: linear-gradient(172deg, #171717, rgb(136, 93, 255));
  scale: 1.02;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spanIcon {
  width: 100%;
}

.spanText {
  text-align: left;
  width: 100%;
}