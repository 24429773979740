.container {
  width: 100%;
  height: 50px;
  display: flex;
	background-color: #000000d0;
	backdrop-filter: blur(10px);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
}

.option {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.option:hover {
  background-color: rgb(74, 74, 74);
  cursor: pointer;
}