.laruinaLogo {
  filter: drop-shadow(1px 1px 8px black)
}

.ulContBtn {
  display: flex;
  flex: column;
  justify-content: center;
}

.btnSubmit {
  background: transparent;
  margin: 20px;
  height: 40px;
  width: 200px;
  bottom: 30px;
  border: none;
  border-radius: 70px;
  background-color: #171717;
  filter: drop-shadow(0px 0px 4px black);
  color: aliceblue;
  font-family: Bebas Neue;
  font-size: 20px;
  transition-duration: .3s;
  border: none;
}

.btnSubmit:hover {
  background-color: #292929;
}