.container {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  z-index: 99;
  transition: 1s;
}

.container:hover {
  cursor: pointer;
}