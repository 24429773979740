.container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  max-width: 2000px;
  list-style: none;
  transition-duration: 1s;
  border-radius: 5px;
  transition-duration: 1s;
  padding-left: 50px;
  padding-right: 50px;
  gap: 1rem;
}

.infoCanvas {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.artist {
  color: #ffffff;
  text-decoration: overline;
  font-size: 18px;
  opacity: 0.7;
  padding: 0;
  margin: 0;
}

.title {
  font-size: 50px;
  color: #ffffff;
  text-shadow: #000000;
  filter: drop-shadow(1px 1px 2px #000000);
  padding: 0;
  margin: 0;
}

.info {
  color: #ffffffd4;
  font-family: Product Sans, sans-serif;
  font-weight: 100;
  font-size: 16px;
  padding: 0;
  margin: 0;
  padding-bottom: 1rem;
}

.userButtons {
  display: flex;
  padding-top: 1rem;
  gap: .4rem
}

.noUserButtons {
  display: flex;
  padding-bottom: 1rem;
  gap: .5rem;
}

.tags {
  display: flex;
  gap: .5rem;
  width: 100%;
  overflow-x: auto;
}

@media only screen and (min-width: 2000px) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 1300px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
    flex-direction: column;
  }
}