.searchCont {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  min-height: 100vh;
  background: linear-gradient(to bottom, #ffffffcf, rgb(137, 137, 137));
  text-align: center;
  padding-top: 90px;
  padding-bottom: 100px;
}

.notFound {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, rgb(201, 201, 201), rgba(109, 109, 109, 0.3), rgb(97, 97, 97));
  background-size: 200% 100%;
  font-size: 10px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.notFound h2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

@keyframes shine {
  0% {
    background-position: -100%;
  }

  100% {
    background-position: 200%;
  }
}

.ulSearchedItem {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 2000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding-top: 2rem;
  padding-left: 50px;
  padding-right: 50px;
  text-align: left;
}

.ulSearchedItem li {
  display: flex;
  gap: 1rem;
}