.outerContainer {
	left: 0;
	bottom: 10px;
	margin: 0;
	padding: 0;
	width: 100vw;
	position: fixed;
	display: flex;
	justify-content: center;
}

.innerContainer {
	margin: 0;
	padding: 0;
	color: aliceblue;
	width: 100%;
	max-width: 2000px;
	height: 100%;
	font-family: Bebas Neue;
	font-size: 15px;
	transition-duration: 1s;
	display: flex;
	padding-left: 50px;
	padding-right: 50px;
}

@media only screen and (max-width: 1300px) {
	.innerContainer {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.ulRequestProfile {
	margin: 0;
	padding: 2rem;
	padding-top: .5rem;
	padding-bottom: .5rem;
	text-decoration: none;
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: #000000d0;
	backdrop-filter: blur(10px);
  border-radius: 15px;
	gap: 1rem;
}

.ulRequestProfile li {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	gap: .5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.borderRight {
	border-right: solid 1px;
}

.spanOpt {
	font-size: 11px;
}

.exitContainer {
	width: 100%;
	height: 50%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.likeIcon,
.listIcon,
.userIcon,
.profilePic {
	width: 30px;
	filter: grayscale(1);
	transition-duration: 1s;
	opacity: .9;
}

.profilePic {
	border-radius: 100%;
	border: solid;
	border-width: 2px;
	filter: drop-shadow(0px 0px 4px black)
}

.adminIcon {
	height: 30px;
	filter: grayscale(1);
	transition-duration: 1s;
	opacity: .9;
}

.notificationIcon {
	width: 30px;
	filter: grayscale(1);
	transition-duration: 1s;
	opacity: .9;
	rotate: -90deg;
	margin-left: 5px;
}

.subscriptionIcon {
	width: 30px;
	filter: grayscale(1);
	transition-duration: 1s;
	opacity: .9;
}

.logoutIcon {
	width: 30px;
	filter: grayscale(1);
	transition-duration: 1s;
	opacity: .9;
}

.listIcon:hover,
.likeIcon:hover,
.userIcon:hover,
.adminIcon:hover,
.logoutIcon:hover,
.profilePic:hover,
.subscriptionIcon:hover,
.notificationIcon:hover {
	cursor: pointer;
	filter: grayscale(.2);
	opacity: 1;
	filter: drop-shadow(0px 0px 8px rgb(255, 255, 255));
	transition-duration: 1s;
}


@media only screen and (max-width: 1300px) {}