.container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 99;
}

.logoContainer {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  transition-duration: .4s;
}

.logoButton {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  gap: 2px;
  overflow: hidden;
  transition-duration: .4s;
}

.containerLogo {
  gap: 5px;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition-duration: .4s;

}

.openMenuButton {
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 10px;
}

.openMenuButton:hover {
  background-color: #383838;
}

.logoInnerContainer {
  width: 100px;
  display: flex;
  justify-content: center;
  transition-duration: .4s;
}

.openMenuButton:hover {
  cursor: pointer;
  color: white;
}

.logoButton span img {
  opacity: .5;
  rotate: 90deg;
}

.logoButton span img:hover {
  cursor: pointer;
  opacity: 1;
}

.ruinaLogo {
  opacity: 0.7;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ruinaLogo:hover {
  cursor: pointer;
  opacity: 1;
  transition-duration: 1s;
}