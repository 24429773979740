.container {
    margin: 0;
    padding: 0;
    border-radius: 5px;
    display: flex;
    gap: .5rem;
}

.adminBtn {
    gap: 1rem;
    margin: 0;
    padding: 0;
    width: 200px;
    border-radius: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition-duration: .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b9b9b9c9;
}

.adminBtn:hover {
    cursor: pointer;
    background-color: #dbdbdbc9;
}

@media only screen and (max-width: 1300px) {
    .adminBtn {
        width: 150px;
        font-size: 14px;
    }
}