
.ulProfileOptions {
  gap: 5px;
  margin: 0;
  height: 0;
  padding: 0;
  width: 0;
  z-index: 9999;
  display: grid;
  flex-direction: column;
  grid-template-columns: 1fr 1fr;
  transition: 1s;
  top: 4.7rem;
  position: absolute;
}

.optionProfileBtn:hover {
  cursor: pointer;
  border-color: white;
  background: linear-gradient(to top, rgb(255, 255, 255), #171717 99%);
  color: #171717;
}

.optionProfileBtn:hover .imgIcon {
  filter: grayscale(0);
}


.imgIcon:hover,
.imgIconProf:hover {
  filter: grayscale(0);
}

.imgIcon {
  filter: grayscale(1);
  height: 30px;
}

.optionProfileBtn {
  margin: 0;
  padding: 0;
  background: linear-gradient(to top, #171717, #171717 99%);
  box-shadow: 0px 0px 4px rgb(130, 128, 128);
  border: solid;
  border-color: #8b8b8b;
  border-width: 1px;
  border-radius: 5px;
  font-size: 12px;
  font-family: Bebas Neue;
  color: rgb(212, 212, 212);
  transition-duration: .5s;
  height: 70px;
  width: 100%;
  transform: translateX(200px);
  scale: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.imgIconProf {
  border-radius: 100%;
  filter: grayscale(1);
  max-width: 25px;
  max-height: 25px;
}

.liProfileMenuDisplay {
  margin: 0;
  padding: 0;
  width: 100%;
  transition: 1s;
}
