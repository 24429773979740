.adminBtn {
  background-color: #b9b9b9c9;
  width: 25px;
  height: 25px;
  border: 5px;
  position: relative;
  margin-top: 0px;
  scale: .9;
  margin-right: 3px;
  opacity: .8;
  transition-duration: .4s;
  border-radius: 5px;
}

.editionButtons {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  padding-top: 3px;
}

.adminRequest {
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 5px;
}

.editImg {
  margin-top: 3px;
  border-radius: 5px;
}

.deleteImg {
  margin-top: 5px;
  border-radius: 5px;
}

.adminBtn:hover {
  opacity: 1;
  scale: 1.02;
  cursor: pointer;
}

.sliderItem {
  margin: 0;
  padding: 0;
  width: 205px;
  height: 0;
  color: #000000;
  position: relative;
  border-radius: 2%;
  background: linear-gradient(to top, transparent, rgb(0, 0, 0));
}

.sliderItem:hover {
  height: 150px;
  transition-duration: 0.5s;
  cursor: pointer;
  background: linear-gradient(to top, #ffffff, rgb(0, 0, 0));
}

.link:link,
.link:visited {
  color: black;
}

.link:hover {
  opacity: 0.9;
}

.sliderInfoCanvas {
  align-items: center;
  display: flex;
  align-items: center;
  opacity: 0;
  width: 100%;
}

.ulTitlesItems {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: .8rem;
  padding-right: .8rem;
}

.title {
  display: flex;
  align-items: center;
  gap: 5px;
}

.sliderItem:hover .sliderInfoCanvas {
  opacity: 1;
  transition-delay: .4s;
  transition-duration: 0.3s;
}

.media {
  width: 205px;
  height: 120px;
  background-position: center;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
  background-color: #000;
  border: none;
  border-radius: 1.3%;
  filter: brightness(0.8);
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 200% 100%;
  animation: shine 2s infinite alternate;
}

@keyframes shine {
  0% {
    background-position: -100%;
  }

  100% {
    background-position: 200%;
  }
}


.media:hover {
  transition-duration: 1s;
  filter: brightness(1);
}

.sliderItemIconPlayN {
  width: 12px;
  height: 12px;
}

@media only screen and (max-width: 1300px) {
  .sliderItem:hover .sliderInfoCanvas {
    display: none;
  }

  .sliderItem:hover {
    background: transparent;
    height: 0;
  }
}
