p {
  font-weight: thin;
}

.linkHub {
  color: rgb(180, 180, 180);
  font-weight: 900;
  font-family: Bebas Neue;
  font-size: 15px;
}

.linkHub:hover {
  color: white;
}

.welcomeCont {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  color: white;
  font-weight: 100;
  text-align: center;
  overflow-y: auto;
  padding-top: 10rem;
  padding-bottom: 5rem;
}

.welcomeCont h1 {
  filter: drop-shadow(0px 0px 4px black);
}

.welcomeUl {
  position: relative;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button-primary,
.button-secondary {
  height: 50px;
  width: 200px;
  position: relative;
  font-family: bebas neue;
  font-size: 20px;
  border: none;
  border-radius: 2%;
  left: -10px;
  backdrop-filter: blur(9px);
}

.separatorContainer {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}