.playerContainer {
  width: 100%;
  aspect-ratio: 16/9;
}

.youtubeComponent {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  border-radius: 15px;
}
