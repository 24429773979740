.container {
  margin: 0;
  padding: 0;
  width: 100%;
}

.cardContainer {
  width: 100%;
  height: 150px;
  color: white;
  border-radius: 10px;
  text-shadow: 0px 0px 4px black;
  box-shadow: 0px 0px 10px black;
  filter: grayscale(.2);
  transition-duration: .4s;
  background-size: cover;
}

.cardContainer:hover {
  filter: grayscale(0);
  cursor: pointer;
  box-shadow: 0px 0px 10px rgb(252, 252, 222);
  background-position: center;
}

.divContSearch {
  margin: 0;
  padding: 0;
  padding-top: 25px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.artist {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.title {
  margin: 0;
  padding: 0;
}