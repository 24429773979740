.container {
  display: flex;
  height: 100vh;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

.editionCanvas {
  width: 0;
  max-width: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  transition-duration: 1s;
  display: flex;
  justify-content: center;
  z-index: 9999;
}

.favIcon {
  filter: grayscale(1);
}

.favIcon:hover {
  filter: grayscale(0);
}

.userButtons {
  display: flex;
  gap: .5rem;
  z-index: 999;
}

.viewer {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  display: flex;
}

.background {
  min-width: 100%;
  min-height: 100%;
  position: relative;
  left: 0px;
  top: 0px;
  animation-timing-function: ease-in-out;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.infoContainer {
  display: flex;
  justify-content: flex-start;
  max-width: 2000px;
  width: 100%;
  flex-direction: column;
}

.viewerCanvas {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), #000000 97%);
  width: 100%;
  height: 100vh;
  position: absolute;
}

.sectionsContainer {
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  min-height: 500px;
  flex-direction: column;
}

.playerContainer {
  margin: 0;
  padding: 0;
  width: 100vw;
  display: flex;
  margin-top: 7rem;
  justify-content: center;
  align-items: center;
  position: relative;
}

.viewerInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  width: 100%;
  animation-timing-function: ease-in-out;
}

.viewMediaTypesList {
  padding: 0;
  margin: 0;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  list-style-type: none;
  list-style: none;
  padding-bottom: 1rem;
}

.visorPostArtista,
.visorPostTitulo,
.visorInfo {
  padding: 0;
  margin: 0;
}

.loaderContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewMediaTypesCont {
  display: flex;
  flex-direction: column;
}

.buttonsContainer {
  display: flex;
}

@media only screen and (min-width: 2000px) {
  .viewerInfo {
    padding-left: 0;
    padding-right: 0;
  }

  .infoContainer {
    padding: 0;
  }
}

@media only screen and (max-width: 1300px) {
  .viewerInfo {
    padding-left: 25px;
    padding-right: 25px;
  }

  .editionCanvas {
    max-width: 1000px;
  }
}