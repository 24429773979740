.container {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttonContainer {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}