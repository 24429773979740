.container {
  display: none;
  width: 500px;
  justify-content: flex-end;
}

@media only screen and (min-width: 1300px) {
  .container {
    display: flex;
  }
}