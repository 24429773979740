.container {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader {
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--primary-white);
  mask: radial-gradient(circle closest-side at 50% 40%, #0000 94%, #000);
  transform-origin: 50% 40%;
  animation: l25 1s infinite linear;
}

.message {
  color: var(--primary-white);
}

@keyframes l25 {
  100% {
    transform: rotate(1turn)
  }
}