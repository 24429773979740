@font-face {
  font-family: Bebas Neue;
  src: url('../assets/fonts/BebasNeue.otf');
}
ul, li{
  list-style: none;
}

:root {
  background: linear-gradient(168deg, rgb(0, 0, 0) 20% , #171717 120%);
  --primary-white: rgb(240, 240, 240);
  overflow-x: hidden;
}

body, .App {
  margin: 0;
  font-family: Bebas Neue;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-attachment: scroll;
  background-repeat: no-repeat;
  list-style: none;
}

.body-app{
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  list-style: none;
  background: linear-gradient(168deg, rgb(0, 0, 0) 20% , #171717 120%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:link, :visited{
  text-decoration: none;
  color: rgba(255, 255, 255, 0.774);
}
:link:hover{
  color: white;
}
::-webkit-scrollbar {
  width: 5px;
  height: 100%;
  z-index: 999999999999999;  
}
::-webkit-scrollbar-track {
  background: linear-gradient(168deg, rgb(0, 0, 0) 20% , #171717 120%);
}
::-webkit-scrollbar-thumb {
  background: #fff2;
  border-radius: 1px;
}
::-webkit-scrollbar-thumb:hover {
  background: #fff4;
}

button:disabled:hover {
  cursor: default;
}

ul {
  margin: 0;
  padding: 0;
}