.reqContainer {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 2000px;
  gap: .5rem;
  display: flex;
  flex-direction: column;
}

.crearProducto, 
.crearPost, 
.editUsers {
  color:aliceblue;
	background-color: #000000d0;
	backdrop-filter: blur(10px);
  border-radius: 15px;
  width: 100%;
  height: 150px;
  min-width: 250px;
  font-family: Bebas Neue;
  font-size: 20px;
  position: relative;
  opacity: .8;
  transition-duration: 1s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.crearProducto:hover, 
.crearPost:hover, 
.editUsers:hover{
  opacity: 1;
  box-shadow: 0px 0px 4px white;
}


@media only screen and (max-width: 900px) {

}