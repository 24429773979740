.container {
  margin: 0;
  padding: 0;
  font-family: Bebas Neue;
  font-size: 20px;
  color: aliceblue;
  background-color: transparent;
  border: none;
  opacity: .8;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.container:hover {
  cursor: pointer;
  color: white;
}