.container {
  padding-top: 10rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.mainContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: linear-gradient(to bottom, #ffffffcf, rgb(137, 137, 137));
}

.createBody {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  padding-top: 10rem;
}

.createForm {
  margin: 0;
  padding: 0;
  width: 330px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: relative;
  border: dashed;
  border-radius: 5px;
}

.createTitle {
  color: white;
  background-color: #171717;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 5px;
  text-align: center;
  position: absolute;
  top: -1.25rem;
  left: -1rem;
}

.contTitleArtistDesc {
  padding-top: 1rem;
  gap: .3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-color: #171717;
  border-width: 1px;
  border-radius: 15px;
  gap: .5rem;
  position: relative;
}

.contTitleArtistDesc span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.contTitleArtistDesc span label {
  position: absolute;
  top: 0;
  font-size: 12px;
  margin-top: -3px;
  margin-left: -.25rem;
  background-color: #171717;
  border-radius: 3px;
  color: white;
  padding-left: .5rem;
  padding-right: .5rem;
}

.buttonsContainer {
  display: flex;
  gap: .5rem;
}

.contTitleArtistDesc span input {
  padding-top: 11px;
  width: 100%;
}

.contTitleArtistDesc span textarea {
  padding-top: 11px;
  width: 100%;
  min-height: 46px;
  max-height: 150px;
  resize: vertical;
  form-sizing: content;
  font-family: Poppins;
}

.imgSlrVsr {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: space-around;
  border-color: #171717;
  border-width: 1px;
  text-align: center;
}

.inputBtn:hover {
  cursor: pointer;
}

textarea {
  resize: none;
}

.btnVolver {
  position: fixed;
  top: 3rem;
  left: 50px;
  width: 180px;
  height: 40px;
  font-family: Bebas Neue;
  font-size: 17px;
  background: linear-gradient(to bottom, #ffffff, rgba(0, 0, 0, 0.679));
  border: none;
  box-shadow: 0px 0px 20px black;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  height: 100px;
  align-items: center;
  justify-content: space-evenly;
}

.submitButtonsContainer {
  display: flex;
  justify-content: space-between;
}

.submit {
  margin-top: 20px;
  padding: 0.3em 0.8em;
  font-family: Bebas Neue;
  font-size: 20px;
  color: white;
  background-color: rgb(32, 32, 32);
  border: none;
  border-radius: 40px;
  transition-duration: .2s;
}

.submit:hover {
  background-color: #8a8a8a;
  color: black;
  box-shadow: 0px 0px 15px white;
}

.submit:disabled {
  background-color: #8a8a8a;
  color: white;
  cursor: not-allowed;
}