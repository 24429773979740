.editListCont {
    position: absolute;
    width: 100%;
    margin: 0px;
    overflow: hidden;
    overflow-y: auto;
    text-align: center;
    display: flex;
    justify-content: center;
}

.adminButtonContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.adminButton {
    background-color: #171717;
    border-radius: 5px;
    padding: .3rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.editListFormat {
    background: linear-gradient(to bottom, #ffffffcf, rgb(137, 137, 137));
    margin-top: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divList {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 2000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: .3rem;
}

.divList li {
    width: 100%;
}

.ulList0 {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgb(1, 1, 1);
    color: rgba(255, 255, 255, 0.854);
    border-radius: 15px;
    box-shadow: 0px 0px 4px #171717;
    transition-duration: .4s;
}

.list {
    margin: 0;
    padding: 0;
    padding-top: .3rem;
    padding-bottom: .3rem;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.739);
    color: rgba(255, 255, 255, 0.854);
    border-radius: 15px;
    box-shadow: 0px 0px 4px #171717;
    transition-duration: .4s;
}

.list li {
    margin: 0;
    padding: 0;
    text-align: center;
    overflow: hidden;
    padding-left: .2rem;
    padding-right: .2rem;
}

.list:hover {
    box-shadow: 0px 0px 4px rgb(255, 255, 255);
    background-color: #4d4d4d;
}

.btnEdit {
    background-color: #c3c3c3;
    background-image: url(../../../../assets/images/edit-icon.png);
    background-size: cover;
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 4px black;
    transition-duration: .4s;
    scale: .95;
    border-color: #c3c3c3;
    border-width: 1px
}

.btnEdit:hover {
    scale: 1;
}